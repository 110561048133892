import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { RootState } from 'store/store'
import { RequestError, RequestStatus } from 'interfaces/common.interface'
import { TrustedDevice } from 'apps/SettingsApp/interfaces/security.interface'

interface SecurityState {
  mfaMethods: string[]
  mfaMethodsStatus: RequestStatus
  mfaDevices: TrustedDevice[]
  mfaDevicesStatus: RequestStatus
  mfaRequestError: RequestError | undefined
}

const initialState: SecurityState = {
  mfaMethods: [],
  mfaMethodsStatus: RequestStatus.Pending,
  mfaDevices: [],
  mfaDevicesStatus: RequestStatus.Pending,
  mfaRequestError: undefined
}

export const fetchUserMfaData = createAsyncThunk('security/fetchUserMfaData', async (bypassCache: boolean = false) => {
  let mfaArray: string[] = []
  const user = await Auth.currentAuthenticatedUser({ bypassCache: bypassCache })
  await user.getUserData((err: any, data: any) => {
    if (err != null) {
      throw new Error('There was a problem with fetching user Mfa data')
    }
    if (data?.UserMFASettingList != null) {
      mfaArray = data.UserMFASettingList
    }
  })
  return mfaArray
})

export const fetchMfaDevices = createAsyncThunk<TrustedDevice[], undefined, { rejectValue: string }>(
  'security/fetchMfaDevices',
  async () => {
    const mfaCurrentUser = await Auth.currentAuthenticatedUser()
    return await new Promise((resolve, reject) => {
      mfaCurrentUser.listDevices(60, null, {
        onSuccess: function (result: any) {
          resolve(result.Devices)
        },
        onFailure: function (err: any) {
          reject(err?.message ?? 'There was a problem with fetching devices')
        }
      })
    })
  }
)

export const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserMfaData.fulfilled, (state, action) => {
        state.mfaMethods = action.payload
        state.mfaMethodsStatus = RequestStatus.Success
      })
      .addCase(fetchUserMfaData.rejected, state => {
        state.mfaMethods = []
        state.mfaMethodsStatus = RequestStatus.Error
      })
      .addCase(fetchMfaDevices.fulfilled, (state, action) => {
        state.mfaDevices = action.payload
        state.mfaDevicesStatus = RequestStatus.Success
        state.mfaRequestError = undefined
      })
      .addCase(fetchMfaDevices.rejected, (state, action) => {
        state.mfaDevices = []
        state.mfaDevicesStatus = RequestStatus.Error
        state.mfaRequestError = action.error.message
      })
  }
})

export const getUserMfaMethods = (state: RootState): string[] => state.settingsApp.security.mfaMethods
export const getMfaMethodsRequestStatus = (state: RootState): RequestStatus =>
  state.settingsApp.security.mfaMethodsStatus
export const doesUserHaveMfa = (state: RootState): boolean => state.settingsApp.security.mfaMethods.length > 0
export const getMfaDevices = (state: RootState): TrustedDevice[] => state.settingsApp.security.mfaDevices
export const getMfaDevicesCount = (state: RootState): number => state.settingsApp.security.mfaDevices.length
export const getMfaDevicesRequestStatus = (state: RootState): RequestStatus =>
  state.settingsApp.security.mfaDevicesStatus
export const getMfaDevicesError = (state: RootState): RequestError | undefined =>
  state.settingsApp.security.mfaRequestError

export default securitySlice.reducer
