import { ApplicantHomeAddress } from 'interfaces/applicant.interface'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'
import { AutomatedVerificationStep } from 'shared_components/ApplicantVerification/hooks/useAutomatedVerificationContext'

export enum CheckFailReason {
  POINameMismatch = 'poi_name_mismatch',
  POINameMissing = 'poi_name_missing',
  POIDocumentUnreadable = 'poi_document_unreadable',
  POIDeviceScreen = 'poi_device_screen',
  POIDocumentExpired = 'poi_document_expired',
  POIDocumentExpiredUnknownReceived = 'poi_document_expired_unknown_received',
  POIFrontImageMissing = 'poi_front_image_missing',
  POIBackImageMissing = 'poi_back_image_missing',
  POIFaceMissing = 'poi_face_missing',
  POIDocumentNotSupported = 'poi_document_not_supported',
  POIDocumentNotSupportedUnknownReceived = 'poi_document_not_supported_unknown_received',
  POIDocumentNotInFrame = 'poi_document_not_in_frame',
  POIApplicantUnder18 = 'poi_applicant_under_18',
  POIPoaDocumentUsed = 'poi_poa_document_used',
  POIFaceMismatch = 'poi_face_mismatch',
  POIMultipleFaces = 'poi_multiple_faces',
  POIOther = 'poi_other',
  POAAddressMismatch = 'poa_address_mismatch',
  POADocumentReused = 'poa_document_reused',
  POANameMismatch = 'poa_name_mismatch',
  POANameMissing = 'poa_name_missing',
  POAWrongDocumentType = 'poa_wrong_document_type',
  POAPoorImageQuality = 'poa_poor_image_quality',
  POANoDocumentOnImage = 'poa_no_document_on_image',
  POADeclaredAddressNotFound = 'poa_declared_address_not_found',
  POADocumentExpired = 'poa_document_expired',
  POADocumentAboutToExpire = 'poa_document_about_to_expire',
  POAOther = 'poa_other'
}

export interface ErrorDetails extends ApplicantHomeAddress {
  name?: string
  document_type?: string
}

interface EvaluatedData {
  expected: ErrorDetails
  received: ErrorDetails | ApplicantHomeAddress
}

export type ErrorValues = Partial<Record<CheckFailReason, EvaluatedData>>
export interface DeclineDetails extends ErrorValues {
  reason: CheckFailReason
}

export enum CheckFlowType {
  selfieOnly = 'selfie_only',
  fullVerification = 'full_verification'
}

export interface AutomatedVerificationCheck {
  id: string
  type: CheckType
  status: CheckStatus
  transaction_link: string
  decline_details?: DeclineDetails
  details?: {
    document_address: ApplicantHomeAddress
    reason: string
  }
  flow_type?: CheckFlowType
}

export interface ApplicantVerification {
  type: ApplicantVerificationType
  forms: AuthorizationDocument[]
  checks: AutomatedVerificationCheck[]
  id: string
}

export enum CheckType {
  IdentityVerification = 'identity_verification',
  ProofOfAddress = 'proof_of_address',
  FormAuthorization = 'form_authorization',
  NotarizeCom = 'notarize.com'
}

export enum CheckStatus {
  Unverified = 'unverified',
  InProgress = 'in_progress',
  Failed = 'failed',
  Verified = 'verified'
}

export enum ApplicantVerificationType {
  AutomaticVerification = 'automatic_verification',
  ExternalNotarization = 'external_notarization',
  ExternalDummy = 'external_dummy',
  AutomaticDummy = 'automatic_dummy',
  AutomaticSimplified = 'automatic_simplified'
}

export enum ApplicantVerificationTypeConsent {
  Default = 'default',
  Onfido = 'onfido'
}

export const CHECKS_STATUS: Record<string, string> = {
  unverified: 'Not started',
  in_progress: 'In progress',
  failed: 'Failed',
  verified: 'Verified'
}

export enum VerificationMediaDocumentType {
  Face = 'face',
  PrimaryFront = 'primary_front',
  PrimaryBack = 'primary_back',
  SecondaryFront = 'secondary_front'
}

export enum DisplayedTip {
  Document,
  Selfie
}

export enum MobileCameraType {
  User = 'user',
  Environment = 'environment'
}

export const checkByStepMap = {
  [AutomatedVerificationStep.SelectDevice]: null,
  [AutomatedVerificationStep.IdentityVerification]: CheckType.IdentityVerification,
  [AutomatedVerificationStep.ProofOfAddress]: CheckType.ProofOfAddress,
  [AutomatedVerificationStep.FormAuthorization]: CheckType.FormAuthorization
}
