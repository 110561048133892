import { combineReducers } from 'redux'
import { AnyAction, CombinedState, Reducer } from '@reduxjs/toolkit'
import eSignSlice from 'apps/SettingsApp/store/e_sign'
import myAddressesSlice from 'apps/SettingsApp/store/my_addresses'
import recipientsStructureMigrationSlice from './recipients_structure_migration'
import scanPreferencesSlice from './scan_preferences'
import currenUserInfoReducer from './user_info'
import securitySlice from './security'
import integrationsSlice from './integrations'
import recipientsSlice from './recipients'
import recipientsBillingInfoSlice from './recipients_billing_info'
import notificationsSlice from './notifications'
import tagsSlice from './tags'
import shippingAddressesSlice from './shipping_addresses'
import depositAccounts from './deposit_accounts'
import recipients1583Slice from './recipients1583'
import accountAutomationSlice, { AccountAutomationState } from './account_automation'
import paymentDetailsSlice from './payment_details'
import forwardRulesSlice from './forward_rules'
import selfieOnlySlice from './selfie_only'

export type SettingsState = ReturnType<typeof combinedReducer>

export const SETTINGS_RESET_APP_ACTION = 'settings/reset'

type CombinedReducersType = CombinedState<{
  currentUserInfo: ReturnType<typeof currenUserInfoReducer>
  security: ReturnType<typeof securitySlice>
  integrations: ReturnType<typeof integrationsSlice>
  recipients: ReturnType<typeof recipientsSlice>
  recipientsBillingInfo: ReturnType<typeof recipientsBillingInfoSlice>
  recipientsStructureMigration: ReturnType<typeof recipientsStructureMigrationSlice>
  scanPreferences: ReturnType<typeof scanPreferencesSlice>
  shippingAddresses: ReturnType<typeof shippingAddressesSlice>
  notifications: ReturnType<typeof notificationsSlice>
  tags: ReturnType<typeof tagsSlice>
  depositAccounts: ReturnType<typeof depositAccounts>
  recipients1583: ReturnType<typeof recipients1583Slice>
  accountAutomation: AccountAutomationState
  myAddresses: ReturnType<typeof myAddressesSlice>
  paymentDetails: ReturnType<typeof paymentDetailsSlice>
  forwardRules: ReturnType<typeof forwardRulesSlice>
  eSign: ReturnType<typeof eSignSlice>
  selfieOnly: ReturnType<typeof selfieOnlySlice>
}>

const combinedReducer: Reducer<CombinedReducersType, AnyAction> = combineReducers({
  currentUserInfo: currenUserInfoReducer,
  security: securitySlice,
  integrations: integrationsSlice,
  recipients: recipientsSlice,
  recipientsBillingInfo: recipientsBillingInfoSlice,
  recipientsStructureMigration: recipientsStructureMigrationSlice,
  scanPreferences: scanPreferencesSlice,
  shippingAddresses: shippingAddressesSlice,
  notifications: notificationsSlice,
  tags: tagsSlice,
  depositAccounts: depositAccounts,
  recipients1583: recipients1583Slice,
  accountAutomation: accountAutomationSlice,
  myAddresses: myAddressesSlice,
  paymentDetails: paymentDetailsSlice,
  forwardRules: forwardRulesSlice,
  eSign: eSignSlice,
  selfieOnly: selfieOnlySlice
})

const settingsReducer: Reducer = (state: SettingsState, action: AnyAction): SettingsState => {
  if (action.type === SETTINGS_RESET_APP_ACTION) {
    return combinedReducer(undefined, action)
  }
  return combinedReducer(state, action)
}

export default settingsReducer
