import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isAxiosError } from 'axios'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestStatus } from 'interfaces/common.interface'
import { AuthorizationDocument } from 'interfaces/authorization_document.interface'

interface AuthorizeRecipientResponse {
  documents: AuthorizationDocument[]
  signature: string
}

interface ESignState {
  requestStatus: RequestStatus
  requestError?: string
  response?: AuthorizeRecipientResponse
}

const initialState: ESignState = {
  requestStatus: RequestStatus.Pending
}

interface AuthorizeRecipientParams {
  accountId: number
  recipientId: string
  preview: boolean
  signature?: string
}

export const authorizeRecipient = createAsyncThunk(
  'eSign/authorizeRecipient',
  async ({ accountId, recipientId, ...params }: AuthorizeRecipientParams) => {
    try {
      const queryParams = new URLSearchParams(Object.entries(params).map(([key, value]) => [key, String(value)]))
      const { data } = await apiClient.post<AuthorizeRecipientResponse>(
        `mail-authorization/accounts/${accountId}/recipients/${recipientId}/authorization?${queryParams.toString()}`
      )

      return data
    } catch (e) {
      throw new Error(
        (isAxiosError(e) && e.response?.data?.error_message) ||
          'An unexpected error occurred. Please try again later or contact support.'
      )
    }
  }
)

export const eSignSlice = createSlice({
  name: 'eSign',
  initialState,
  reducers: {
    resetESign: () => initialState
  },
  extraReducers: builder =>
    builder
      .addCase(authorizeRecipient.pending, state => ({
        ...state,
        requestError: undefined,
        requestStatus: RequestStatus.Loading
      }))
      .addCase(authorizeRecipient.fulfilled, (state, action) => ({
        ...state,
        requestStatus: RequestStatus.Success,
        response: action.payload
      }))
      .addCase(authorizeRecipient.rejected, (_, action) => ({
        requestStatus: RequestStatus.Error,
        requestError: action.error.message
      }))
})

export const { resetESign } = eSignSlice.actions

export const getIsESignLoading = (state: RootState): boolean =>
  state.settingsApp.eSign.requestStatus === RequestStatus.Loading
export const getESignResponse = (state: RootState): AuthorizeRecipientResponse | undefined =>
  state.settingsApp.eSign.response
export const getIsESigned = (state: RootState): boolean => !!state.settingsApp.eSign.response?.signature
export const getESignErrorMessage = (state: RootState): string | undefined => state.settingsApp.eSign.requestError

export default eSignSlice.reducer
