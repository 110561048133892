import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Auth } from 'aws-amplify'
import { CognitoUser } from '@aws-amplify/auth'
import { RootState } from 'store/store'
import { CurrentUserInfo, CurrentUserAttributes } from 'interfaces/settings_user.interface'
import { IdpProviders, RequestError, RequestStatus } from 'interfaces/common.interface'

export interface AuthCurrentInfoState {
  authCurrentUserInfo: CurrentUserInfo | null
  status: RequestStatus
  error: RequestError | null
}

const initialState: AuthCurrentInfoState = {
  status: RequestStatus.Pending,
  authCurrentUserInfo: null,
  error: null
}

export const providerNameFromIdentities = (identities: string | undefined): string | undefined => {
  if (identities === undefined) return
  const parsedIdentities = JSON.parse(identities) as Array<{ providerName?: string }>
  if (parsedIdentities.length === 0) return
  return parsedIdentities[0].providerName === 'SignInWithApple'
    ? IdpProviders.Apple
    : (parsedIdentities[0].providerName as IdpProviders)
}

export const fetchAuthCurrentUserInfo = createAsyncThunk('authCurrentUserInfo/fetchAuthCurrentUserInfo', async () => {
  const user: CognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true })

  return new Promise<AuthCurrentInfoState['authCurrentUserInfo']>((resolve, reject) =>
    user.getUserData((err, result) => {
      if (err) {
        reject(err)
      } else if (!result) {
        resolve(null)
      } else {
        resolve({
          username: result.Username,
          attributes: Object.fromEntries(result.UserAttributes.map(({ Name, Value }) => [Name, Value])),
          mfaEnabled: result.UserMFASettingList?.length > 0
        })
      }
    })
  )
})

export const authCurrentUserInfoSlice = createSlice({
  name: 'authCurrentUserInfo',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAuthCurrentUserInfo.fulfilled, (state, action) => {
        state.authCurrentUserInfo = action.payload
        state.status = RequestStatus.Success
      })
      .addCase(fetchAuthCurrentUserInfo.pending, state => {
        state.status = RequestStatus.Loading
      })
      .addCase(fetchAuthCurrentUserInfo.rejected, state => {
        state.status = RequestStatus.Error
      })
  }
})

export const getIsLZUser = (state: RootState): boolean =>
  providerNameFromIdentities(state.authCurrentUserInfo.authCurrentUserInfo?.attributes?.identities) ===
  IdpProviders.LegalZoom
export const getIsAuthCurrentUserInfoReady = (state: RootState): boolean =>
  state.authCurrentUserInfo.status === RequestStatus.Success
export const getIsAuthCurrentUserInfoLoading = (state: RootState): boolean =>
  state.authCurrentUserInfo.status === RequestStatus.Loading
export const getCurrentUserAttributes = (state: RootState): CurrentUserAttributes | undefined =>
  state.authCurrentUserInfo.authCurrentUserInfo?.attributes
export const getHasCurrentUserMfaEnabled = (state: RootState): boolean =>
  !!state.authCurrentUserInfo.authCurrentUserInfo?.mfaEnabled

export default authCurrentUserInfoSlice.reducer
