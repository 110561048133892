import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from 'store/store'
import { apiClient } from 'utils/api'
import { RequestError, RequestStatus } from 'interfaces/common.interface'

export interface SelfieOnlyDetails {
  primary_id_details: {
    type: {
      id: string
      display_name: string
      backside: boolean
    }
    first_name: string
    last_name: string
    number: string
    expiration_date: string
  }
  secondary_id_details: {
    type: {
      id: string
      display_name: string
      backside: boolean
    }
    address: {
      address_line_1: string
      address_line_2: string
      city: string
      state: string
      zip_code: string
      country: string
    }
  }
}

interface SelfieOnlyState {
  details: SelfieOnlyDetails | undefined
  status: RequestStatus | undefined
  error: RequestError | undefined
}

interface SelfieOnlyRequestParams {
  accountId: string | number
  recipientId: string
}

interface SelfieOnlyPostParams extends SelfieOnlyRequestParams {
  phoneNumber?: string
}

const initialState: SelfieOnlyState = {
  details: undefined,
  status: undefined,
  error: undefined
}

export const fetchSelfieOnlyDetails = createAsyncThunk(
  'selfieOnly/fetchSelfieOnlyStatus',
  async ({ accountId, recipientId }: SelfieOnlyRequestParams) => {
    const response: { data: SelfieOnlyDetails } = await apiClient.get(
      `/mail-authorization/accounts/${accountId}/recipients/${recipientId}/selfie_only_reverification_details`
    )
    return response.data
  }
)

export const deleteSelfieOnlyDetails = createAsyncThunk(
  'selfieOnly/deleteSelfieOnlyDetails',
  async ({ accountId, recipientId }: SelfieOnlyRequestParams) => {
    await apiClient.delete(
      `/mail-authorization/accounts/${accountId}/recipients/${recipientId}/selfie_only_reverification_details`
    )
  }
)

export const updateApplicantForSelfieOnly = createAsyncThunk(
  'selfieOnly/updateApplicantForSelfieOnly',
  async ({ accountId, recipientId, phoneNumber }: SelfieOnlyPostParams) => {
    const response: { data: SelfieOnlyDetails } = await apiClient.post(
      `/mail-authorization/accounts/${accountId}/recipients/${recipientId}/selfie_only_reverification_details`,
      { phone_number: phoneNumber }
    )
    return response.data
  }
)

export const selfieOnlySlice = createSlice({
  name: 'selfieOnly',
  initialState,
  reducers: {
    resetSelfieOnlyDetails: () => initialState,
    disableSelfieOnly: state => {
      state.details = undefined
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSelfieOnlyDetails.fulfilled, (state, action) => {
        state.status = RequestStatus.Success
        state.details = action.payload
        state.error = undefined
      })
      .addCase(fetchSelfieOnlyDetails.pending, (state, action) => {
        state.status = RequestStatus.Pending
        state.error = undefined
        state.details = undefined
      })
      .addCase(fetchSelfieOnlyDetails.rejected, (state, action) => {
        state.status = RequestStatus.Error
        state.error = action.error.message
        state.details = undefined
      })
  }
})

export const { resetSelfieOnlyDetails, disableSelfieOnly } = selfieOnlySlice.actions

export const getIsSelfieOnlyFlowDisabled = (state: RootState): boolean =>
  state.settingsApp.selfieOnly.details === undefined && state.settingsApp.selfieOnly.status === RequestStatus.Success
export const getIsCheckingSelfieOnlyFlow = (state: RootState): boolean =>
  state.settingsApp.selfieOnly.status === RequestStatus.Pending
export const getIsSelfieOnlyStatusChecked = (state: RootState): boolean =>
  state.settingsApp.selfieOnly.status !== undefined && state.settingsApp.selfieOnly.status !== RequestStatus.Pending
export const getIsSelfieOnlyFlowAvailable = (state: RootState): boolean =>
  state.settingsApp.selfieOnly.details !== undefined
export const getSelfieOnlyDetails = (state: RootState): SelfieOnlyDetails | undefined =>
  state.settingsApp.selfieOnly.details
export const getShouldDeleteSelfieOnlyDetails = (state: RootState): boolean =>
  getIsSelfieOnlyFlowDisabled(state) || getIsSelfieOnlyFlowAvailable(state)

export default selfieOnlySlice.reducer
